<template>
    <ul class='code_list all_white'>
        <NoData v-if="infos.length < 1"/>
        <div v-else >
            <li class="code_list_item" v-for="(item, index) in infos" :key="index"  @click="chooseNum">
            <div class="item_info">
                <p><b>{{gameInfo.name}}</b></p>
                <p><b>第{{item.issueName}}期</b></p>
                <p>{{item.time}}</p>
            </div>
            <div class="ball_content">
                <ball 
                    v-for="(ball, index) in item.redBall" 
                    :value="ball" 
                    :isChoose="true"
                    :key="index"/>
                <ball 
                    v-for="(ball, index) in item.blueBall" 
                    :isChoose="true"
                    :value="ball" 
                    color="blue"
                    :key="'0'+index"/>
            </div>
            <!-- <div class="choose_num">
                选号
            </div> -->
        </li>
        <p>已显示近半年全部开奖公告</p>
        </div>
    </ul>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { wincodeList } from '../../utils/api' 
import { getQueryStr, jumpTo, openBonusTime} from '../../utils/util'
import Ball from '../../components/Ball.vue'
import { gameIDMap } from '../../common-data'
export default {
    name: '',
    components: { Ball },
    setup() {
        const state= reactive({
            infos: [],
            inWhiteList: false
        })
        const gameId = parseInt(getQueryStr('gameId')) || 11
        async function loadData() {
            try {
                const result = await wincodeList(gameId)
                if (result.code == 0) {
                    state.infos = result.data
                    initData(state.infos)
                }
            } catch(e) {
                console.error(e)
            }
        }
        function initData(data) {
            data.forEach(ele => {
                ele.time = openBonusTime(ele.prizeDate)
                const code = ele.winningCode.split('#')
                ele.redBall = code[0].split(',')
                ele.blueBall = code[1] && code[1].split(',')
            });
        }
        function chooseNum() {
            if (state.inWhiteList) {
                jumpTo(`/choosenumber?gameId=${gameId}`)
            }
        }
        const gameInfo = gameIDMap.get(gameId)
        loadData()
        return {
            ...toRefs(state),
            gameInfo,
            chooseNum
        }
    },
}
</script>
<style lang='scss' scoped>
.code_list {
    padding: $margin-big;
    &_item {
        background: $bg-gray;
        margin-bottom: $margin-big;
        padding: $margin-big;
        border-radius: $box-radius-small;
        position: relative;
       .item_info {
           display: flex;
           margin: 0 0 3px 5px;
           p {
               font-size: $font-less;
               margin-right: 3px;
               color: $gray-middle;
               line-height: 1em;
               height: 1em;
               b {
                   font-size: $font-middle;
                   font-weight: 500;
                   color: #000;
               }
           }
       }
       .ball_content {
        //    padding-right: 45px;
           flex-wrap: wrap;
           display: flex;
       }
       .choose_num {
           color: #fff;
           background: $theme-dark;
           position: absolute;
           right: $margin-big;
           top: 38px;
           font-size: $font-small;
           padding: 3px 10px;
           border-radius: $box-radius-small;
       }
    }
    p {
        text-align: center;
        color: $gray-light;
        padding: 5px 0 5px 0;
        font-size: $font-small;
    }
}
</style>